import * as React from 'react';

export default function HomePage(props) {
function myFunction() {
    const isBrowser = typeof window !== "undefined"
    if(isBrowser){
        window.location.replace("https://games.expinfi.com")
    }
}
myFunction()
  return ("redirecting...")
}
